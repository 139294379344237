<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>홀덤배팅내역</sub-title>
        <bet-info-links></bet-info-links>
        <div style="margin: 5px 3px;display: flex;align-items: center;justify-content: flex-end">
          <button @click="deleteAll" class="badge badge-red" style="padding: 5px 15px;margin-left: 2px"> 전체삭제</button>
        </div>
        <div class="board_list">
          <table class="utable">
            <tr>
              <th style="width: 10%">시간</th>
              <th style="width: 10%">ID</th>
              <th style="width: 20%">라운드</th>
              <th style="width: 10%">배팅금액</th>
              <th style="width: 10%">당첨금</th>
              <th style="width: 10%">결과</th>
            </tr>
            <tr v-for="(item,idx) in resultList" :key="idx">
              <td>{{ item.createTime|datef('MM-DD HH:mm') }}</td>
              <td>{{ item.id }}</td>
              <td>
                {{ item.roundId }}
              </td>
              <td>
                {{ item.betamount | comma }}
              </td>
              <td>
                {{ item.winamount | comma }}
              </td>
              <td>
                <span v-if="item.betResult === 1" class="text-skyblue">당첨</span>
                <span v-if="item.betResult === 2" class="">x</span>
              </td>
            </tr>
          </table>
        </div>
        <pagination :page-index="pageNum"
                    :total="total"
                    :page-size="pageSize"
                    @change="pageChange"></pagination>
      </div>
    </div>

    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>
  </div>

</template>

<script>
import RightBarComp from "../../components/RightBarComp";
import LeftBarComp from "../../components/LeftBarComp";
import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
import SubTitle from "../../components/SubTitle";
import leisureConst from "../../common/leisureConst";
import sportsConst from "../../common/sportsConst";
import {
  deleteLeisuerAll,
  deleteT365BetAll,
  deleteT365BetById,
  getThirdPartGameResult
} from "../../network/leisureRequest";
import Pagination from "../../components/pagenation/Pagination";
import LeisureGameLinksComp from "../../components/leisuregame/LeisureGameLinksComp";
import ScrollTopComp from "../../components/ScrollTopComp";
import FootComp from "../../components/FootComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import GameResultLinks from "../../components/GameResultLinks";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import UserInfoComp from "../../components/UserInfoComp";
import TopbarComp from "../../components/TopbarComp";
import {deleteAllReachBet, getReachBetList} from "../../network/casinoReachRequest";
import BetInfoLinks from "@/components/BetInfoLinks.vue";


export default {
  name: "ReachCasinoBetInfo",
  components: {
    BetInfoLinks,
    TopbarComp,
    UserInfoComp,
    RightButtonsComp,
    SportsBetCartComp,
    GameResultLinks,
    SportsLeftBarComp,
    RightBarBannerComp,
    FootComp,
    ScrollTopComp, LeisureGameLinksComp, Pagination, SubTitle, LeisureLeftComp, LeftBarComp, RightBarComp
  },
  data() {
    return {
      leisureConst,
      sportsConst,
      pageNum: 1,
      pageSize: 10,
      total: 1,
      resultList: []
    }
  },
  methods: {
    deleteAll() {
      this.$swal({
        title: '배팅내역 전체를 삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteAllReachBet().then(res => {
            this.getReachBetList()
          })
        }
      });

    },
    getReachBetList() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getReachBetList(this.pageNum, this.pageSize, this.orderBy).then(res => {
        if (res.data.success) {
          this.total = res.data.total
          this.resultList = res.data.data
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    },
    pageChange(page) {
      this.pageNum = page
      this.getReachBetList()
    }
  },
  created() {
    this.getReachBetList();
  },
  watch: {}
}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");

.button_group button {
  border: 2px solid #ffffff;
}

.result p {
  display: inline-block;
  margin-right: 5px;
}

@media screen and (max-width: 800px) {
  .result p {
    display: block;
    margin-right: 0;
  }
}
</style>